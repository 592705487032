import firebase from "firebase";

import { createZendeskTemplate } from "../utils/createZendeskTemplate";
import { getSignedInUser } from "../../Auth/services/user";
import { sendToZendesk } from "../../../services/zendeskService";
import { createFirestoreDocument } from "../utils/createFirestoreDocument";
import { createRecord, getRecord } from "../../../services/airtableEndpoint";
import { createNutritionRecord } from "../utils/createNutritionRecord";

async function sendNutritionistToZendesk({ user, state, formFields }) {
	const zendeskBody = createZendeskTemplate({ state, formFields });

	let ticketTags = ["maxicare_mh", "maxicare", "new_request", "nutrition"];
	if (process.env.GATSBY_ENV !== "production") ticketTags.push("test");

	let zendeskRequestPayload = {
		type: "request",
		subject:
			(process.env.GATSBY_ENV !== "production" ? "[TEST] " : "") +
			"Nutrition Teleconsult Request",
		requester: {
			name: user?.userData?.firstName,
			email: user?.userData?.email,
		},
		tags: ticketTags,
		comment: {
			html_body: zendeskBody,
		},
	};

	const response = await sendToZendesk({
		request: zendeskRequestPayload,
	});

	return response?.data?.request?.id;
}

async function sendNutritionistToFirebase({ state, zendeskId, user }) {
	const document = await createFirestoreDocument({ state, zendeskId, user });
	const docRef = await firebase
		.firestore()
		.collection("consultations")
		.add(document);

	return docRef;
}

async function sendNutritionToAirtable({
	state,
	user,
	zendeskId,
	nutritionDocument,
}) {
	const airtableRecord = await createNutritionRecord({
		state,
		user,
		zendeskId,
		nutritionDocument,
	});

	const result = await createRecord({
		table: "Consultations",
		base: process.env.GATSBY_MH_OPS_BASE_ID,
		fields: airtableRecord,
	});

	return result;
}

export async function handleSubmitNutritionist({
	state,
	formFields,
	callback,
}) {
	try {
		const user = getSignedInUser();

		const zendeskId = await sendNutritionistToZendesk({
			user,
			state,
			formFields,
		});

		const document = await sendNutritionistToFirebase({
			state,
			zendeskId,
			user,
		});

		const result = await sendNutritionToAirtable({
			state,
			user,
			zendeskId,
			nutritionDocument: document,
		});

		if (callback) {
			callback();
		}
	} catch (error) {
		console.error("ERROR CODE: 411", error);
	}
}
