export const createFirestoreDocument = async ({ state, zendeskId, user }) => {
	const preferredSchedule = {
		firstChoiceSchedule: {
			day: state.firstChoiceSchedule.day.value,
			time: state.firstChoiceSchedule.time.value,
		},
		secondChoiceSchedule: {
			day: state.secondChoiceSchedule.day.value,
			time: state.secondChoiceSchedule.time.value,
		},
		thirdChoiceSchedule: {
			day: state.thirdChoiceSchedule.day.value,
			time: state.thirdChoiceSchedule.time.value,
		},
	};

	let document = {
		weight: state.weight?.value || "N/A",
		height: state.height?.value || "N/A",
		lastMeasurementTaken: state.lastMeasurementTaken || "N/A",
		usualBP: state.usualBP || "N/A",
		lastVisitedDoctorRadio: state.lastVisitedDoctorRadio || "N/A",
		smokingHistory: state.smokingHistory || "N/A",
		drinkAlcohol: state.drinkAlcohol || "N/A",
		alcoholAmount: state.alcoholAmount || "N/A",
		medicalHistory: state?.medicalHistory?.[0] || "N/A",
		otherMedicalHistory: state?.otherMedicalHistory || "N/A",
		familyMedicalHistory: state.familyMedicalHistory || "N/A",
		otherFamilyMedicalHistory: state.otherFamilyMedicalHistory || "N/A",
		medicinesTakeRegularly: state.medicinesTakeRegularly?.[0] || "N/A",
		otherMedicinesTakenRegularly: state.otherMedicinesTakenRegularly || "N/A",

		consultNutritionistReason: state.consultNutritionistReason || "N/A",
		primaryNutritionalGoal: state.primaryNutritionalGoal || "N/A",
		consultedNutritionistPast: state.consultedNutritionistPast || "N/A",
		reasonConsultedNutrionistPast: state.reasonConsultedNutrionistPast || "N/A",
		foodAllergies: state.foodAllergies || "N/A",
		otherFoodAllergies: state.otherFoodAllergies || "N/A",
		usualMealSnacks: state.usualMealSnacks || "N/A",
		frequencyOfWater: state.frequencyOfWater || "N/A",
		frequencyOfBowelMovement: state.frequencyOfBowelMovement || "N/A",

		eatingPatternSatisfaction: state.eatingPatternSatisfaction || "N/A",
		secretEating: state.secretEating || "N/A",
		weightFeelings: state.weightFeelings || "N/A",
		familyEatingDisorder: state.familyEatingDisorder || "N/A",
		currentlySufferingEatingDisorder:
			state.currentlySufferingEatingDisorder || "N/A",
		physicalActivityLevel: state.physicalActivityLevel || "N/A",
		usualSleepHours: state.usualSleepHours?.value || "N/A",
		usualHourOfSleep: state.usualHourOfSleep || "N/A",

		preferredSchedule,
		ifScheduleUnavailable: state.ifScheduleUnavailable || "N/A",
		hasUsedGoogleMeet: state.hasUsedGoogleMeet || "N/A",
		otherNotes: state.otherNotes || "N/A",

		created: new Date(),
		zendeskId: zendeskId,
		userId: user.userData.id || "N/A",
		authUid: user.authUser.uid || "N/A",
		type: "MGXSOLMH",
	};

	return document;
};
