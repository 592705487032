import { formatDate } from "../../../services/date";

export const createZendeskBodyFields = ({ fields = [], state }) => {
	let bodyFields = [];

	fields.forEach((field) => {
		const { name, type } = field;
		let fieldValue = state?.[name];
		let fieldLabel = field?.label;

		if (fieldLabel === undefined) return "";

		switch (type) {
			case "text":
			case "radio":
			case "scale":
				bodyFields.push(`${fieldLabel}: ${fieldValue || "N/A"}`);
				break;
			case "checkbox":
				bodyFields.push(`${fieldLabel}: ${fieldValue?.join(", ") || "N/A"}`);
				break;
			case "height":
			case "weight":
			case "select":
				bodyFields.push(`${fieldLabel}: ${fieldValue?.value || "N/A"}`);
				break;
			case "date":
				if (fieldValue?.month?.value) {
					bodyFields.push(
						`${fieldLabel}: ${formatDate({
							date: fieldValue,
							format: "ll",
						})}`
					);
				}
				break;
			case "schedule":
				if (fieldValue?.day?.value) {
					bodyFields.push(
						`${fieldLabel}: ${fieldValue?.day?.value}, ${fieldValue?.time?.value}`
					);
				}
				break;
			case "address":
				bodyFields.push(
					`Address Type: ${
						fieldValue?.deliverToOffice?.length > 0 ? "Office" : "Home"
					}<br/>Street Address: ${fieldValue?.houseNumber} ${
						fieldValue?.streetAddress || "N/A"
					}
					<br/>Barangay: ${fieldValue?.barangay?.value || "N/A"}<br/>City: ${
						fieldValue?.city?.value || "N/A"
					}<br/>Province: ${
						fieldValue?.province?.value || "N/A"
					}<br/>Delivery Notes: ${fieldValue?.notes || "N/A"}<br/>Site Name: ${
						fieldValue?.siteName?.value || "N/A"
					}`
				);
				break;
			default:
				if (fieldValue && fieldLabel)
					bodyFields.push(`${fieldLabel}: ${fieldValue || "N/A"}`);
				break;
		}
	});

	return bodyFields.join("<br/>");
};
