export async function getRecord({ table, query, base }) {
	const response = await fetch(
		`${process.env.GATSBY_AIRTABLE_FUNCTION_URL}/select`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				base,
				table,
				query,
			}),
		}
	).then((res) => res.json());

	return response[0] || null;
}

export async function createRecord({ table, base, fields }) {
	const response = await fetch(
		`${process.env.GATSBY_AIRTABLE_FUNCTION_URL}/create`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				base,
				table,
				fields,
			}),
		}
	).then((res) => res.json());

	return response || null;
}
