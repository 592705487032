import { createZendeskBodyFields } from "./createZendeskBodyFields";

export function createZendeskTemplate({
	internalNote,
	heading,
	formFields,
	state,
}) {
	let template = "";
	let modules = [
		...new Set(
			formFields
				.filter((field) => field.showOnWebsite) // Or use `showOnZendesk` if applicable
				.map((field) => field.module)
		),
	];

	const modulesWithFields = modules
		.map((module) => {
			const moduleFields = formFields
				.filter(
					(field) => field.module === module && field.showOnWebsite === true
				)
				.sort((fieldA, fieldB) => fieldA.order - fieldB.order);

			return {
				module: module,
				fields: moduleFields,
				order: moduleFields.length > 0 ? moduleFields[0].sectionOrder : null,
			};
		})
		.sort((a, b) => a.order - b.order);

	let header = `<strong style="color:#0d6d6e;"> ${
		!!heading ? heading : "NUTRITION"
	} </strong><br/><br/>`;

	template += header;

	// generate labels and values for each module
	modulesWithFields.forEach((module) => {
		if (!internalNote) {
			template += `<strong><u>${
				module.module === "Enrollment" ? "Personal Information" : module.module
			}</u></strong><br/><br/>`;
		}

		template += createZendeskBodyFields({
			fields: module.fields,
			state: state,
		});

		template += "<br/><br/>";
	});

	return template;
}
