import moment from "moment";

import { getRecord } from "../../../services/airtableEndpoint";

export const createNutritionRecord = async ({
	state,
	user,
	zendeskId,
	nutritionDocument,
}) => {
	let website = await getRecord({
		table: "Websites",
		base: process.env.GATSBY_MH_OPS_BASE_ID,
		query: { filterByFormula: `{Name}='MGX Teleconsult Website'` },
	});

	let employee = await getRecord({
		table: "Employees",
		base: process.env.GATSBY_MH_OPS_BASE_ID,
		query: {
			filterByFormula: `{Email}='${user?.userData?.email}'`,
		},
	});

	let fields = {
		"Zendesk ID": zendeskId,
		"Consult Type": "Nutritionist-Dietitian",
		"Consult Type Instance": "First Time",
		"Program Code": user?.userData?.accessCode,
		"Request Date": moment(),
		"Firestore Document ID": nutritionDocument.id,
		Website: website?.id ? [website?.id] : null,
		Type: "Self Request",
		Employee: employee?.id ? [employee?.id] : null,
		Environment: process.env.GATSBY_ENV,
		Status: "New",
		Via: "Website",
	};

	fields = {
		"Employee First Name Static": user?.userData?.firstName,
		"Employee Last Name Static": user?.userData?.lastName,
		"Employee Email Static": user?.userData?.email,

		"Preferred Schedule 1": `${state?.firstChoiceSchedule?.day.value} ${state?.firstChoiceSchedule?.time.value}`,
		"Preferred Schedule 2": `${state?.secondChoiceSchedule?.day.value} ${state?.secondChoiceSchedule?.time.value}`,
		"Preferred Schedule 3": `${state?.thirdChoiceSchedule?.day.value} ${state?.thirdChoiceSchedule?.time.value}`,
		"If Schedule Unavailable": state?.ifScheduleUnavailable,
		"Has Used GMeet Before": state?.hasUsedGoogleMeet,
		"Other Concerns": state?.otherNotes,

		"Weight (kg/lbs)": state?.weight?.value,
		"Height (cm/ft)": state?.height?.value,
		"Last Measurement Take": state.lastMeasurementTaken,
		"Usual Blood Pressure": state.usualBP,
		"Last Doctor Visit": state.lastVisitedDoctorRadio,
		"Smoking History": state.smokingHistory,
		"Alcohol Consumption Frequency": state.drinkAlcohol,
		"Alcohol Consumption Amount": state.alcoholAmount || 0,
		"Medical History": (state.medicalHistory || []).join(", "),
		"Specific Medical History": state.otherMedicalHistory,
		"Family Medical History": (state.familyMedicalHistory || []).join(", "),
		"Specific Family Medical History": state.otherFamilyMedicalHistory,
		"Medicines Taken Regularly": (state.medicinesTakeRegularly || []).join(
			", "
		),
		"Specific Medicines Taken Regularly": state.otherMedicinesTakenRegularly,

		"Specific Issue": state.consultNutritionistReason,
		"Primary Nutritionist Reason": state.primaryNutritionalGoal,
		"Past Nutritionist Consult": state.consultedNutritionistPast,
		"Past Consult Reason": state.reasonConsultedNutrionistPast,
		"Food Allergies": (state.foodAllergies || []).join(", "),
		"Specific Food Allergies": state.otherFoodAllergies,
		"Usual Meals": (state.usualMealSnacks || []).join(", "),
		"Water Intake Frequency": state.frequencyOfWater,
		"Bowel Movement": state.frequencyOfBowelMovement,

		"Eating Pattern Satisfaction": state.eatingPatternSatisfaction,
		"Secret Eating": state.secretEating,
		"Weight Perception": state.weightFeelings,
		"Family Eating Disorder": state.familyEatingDisorder,
		"Current Eating Disorder": state.currentlySufferingEatingDisorder,
		"Physical Activity Level": state.physicalActivityLevel,
		"Usual Sleeping Hours": state?.usualSleepHours?.value,
		"Usual Hours of Sleep": state.usualHourOfSleep || 0,
		...fields,
	};

	return fields;
};
