import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { get } from "lodash";
import classNames from "classnames";

import Layout from "layout";
import Section from "elements/Section";
import Container from "layout/Container";
import FileThumbnail from "elements/UploadDocumentDropzone/FileThumbnail";
import EditDetailsButton from "elements/EditDetailsButton";
import { isBrowser } from "services/general";

import NutritionistSummaryConsent from "./NutritionistSummaryConsent";
import { NutritionistContext } from "./context";
import { useSectionFormFields } from "./context/hooks/useSectionFormFields";
import { getSignedInUser } from "../Auth/services/user";
import { updateConsentAndAuthorization } from "./services/updateConsentAndAuthorization";

import styles from "./utils/nutritionistSummary.module.scss";
import { handleSubmitNutritionist } from "./services/handleSubmitNutritionist";

let { parseFormField } = require("services/airtable");

const NutritionistSummary = ({ pageContext }) => {
	const [loading, setLoading] = useState(false);
	const { nutritionistState, nutritionistDispatch } = useContext(
		NutritionistContext
	);

	useEffect(() => {
		if (isBrowser()) {
			nutritionistDispatch({ type: "GET_CONTEXT_FROM_SESSION" });
		}
	}, [nutritionistDispatch]);

	let { sectionFormFields, validationSchema } = useSectionFormFields({
		formFields: pageContext.formFields,
	});

	// Get only consentAndAuthorization field under Schedule module
	sectionFormFields = sectionFormFields.filter(
		(section) => section.section === "Consent and Authorization"
	);

	const accessRule = getSignedInUser().accessRule;

	if (accessRule?.redirectLinks) {
		sectionFormFields = updateConsentAndAuthorization({
			sectionFormFields,
			accessRule,
		});
	}

	let sectionSummaryFields = parseFormField(
		pageContext.summaryFields.map((formField) => ({
			...formField,
			section: formField.summarySection,
		}))
	);

	sectionSummaryFields = sectionSummaryFields.sort(
		(firstSection, secondSection) => firstSection.order - secondSection.order
	);

	// Hardcoded values based on path values for each page
	const editKey = {
		"Medical History": "/booking/nutritionist/medical-history",
		"Dietary Information": "/booking/nutritionist/dietary-information",
		"Dietary Screening": "/booking/nutritionist/dietary-screening",
		Schedule: pageContext.backPath,
	};

	const handleSubmit = async () => {
		setLoading(true);
		handleSubmitNutritionist({
			formFields: pageContext.summaryFields,
			state: nutritionistState,
			callback: () => {
				setLoading(false);
				navigate(pageContext.nextPath);
			},
		});
	};

	return (
		<Layout
			title={pageContext?.module?.title}
			subtitle={pageContext?.module?.subtitle}
			seoTitle={pageContext?.module?.title}
			pageContext={pageContext}
			verifyPage
			isPrivate
			progress
			isForms
		>
			<Container isCentered>
				{sectionSummaryFields.map((section) => {
					return (
						<Section
							title={section?.section}
							addOns={{
								right: editKey[section?.section] ? (
									<EditDetailsButton route={editKey[section?.section]} />
								) : null,
							}}
						>
							<div className="table-container mb-1-mobile">
								<table class="table is-fullwidth message-text-size">
									<tbody>
										{section.fields
											.filter((field) => {
												// Only display the fields related to Mental Health
												return field.program.includes("MGX1SOLMH");
											})
											.map((field) => {
												let finalValue = null;
												switch (field.type) {
													case "select":
														finalValue = get(nutritionistState, field.name)
															? get(nutritionistState, field.name)?.label
															: "";
														break;
													case "date":
														finalValue = get(nutritionistState, field.name)
															? `${
																	get(nutritionistState, field.name)?.month
																		?.label
															  } ${
																	get(nutritionistState, field.name)?.date
																		?.label
															  }, ${
																	get(nutritionistState, field.name)?.year.label
															  }`
															: "";
														break;
													case "address":
														finalValue = get(nutritionistState, field.name)
															? `${
																	get(nutritionistState, field.name)
																		?.streetAddress
															  }, ${
																	get(nutritionistState, field.name)?.city
																		?.label
															  }, ${
																	get(nutritionistState, field.name)?.province
																		?.label
															  }`
															: "";
														break;
													case "upload":
														finalValue = nutritionistState.documents?.map(
															(document) => <FileThumbnail file={document} />
														);
														finalValue = !finalValue.length && null;
														break;
													case "schedule":
														finalValue = get(nutritionistState, field.name)
															? `${
																	get(nutritionistState, field.name)?.day?.label
															  }, ${
																	get(nutritionistState, field.name)?.time
																		?.label
															  }`
															: "";
														break;
													case "checkbox":
														if (Array.isArray(nutritionistState[field.name])) {
															finalValue = nutritionistState[field.name].join(
																", "
															);
														} else {
															finalValue = nutritionistState[field.name];
														}
														break;
													default:
														finalValue = get(nutritionistState, field.name);
														break;
												}

												if (!!finalValue)
													return (
														<tr>
															<td className="has-text-weight-bold py-1">
																{field.summaryLabel}
															</td>
															<td
																className={classNames(
																	"py-1 pl-2",
																	styles["summary__tableData"]
																)}
															>
																{finalValue}
															</td>
														</tr>
													);
												return null;
											})}
									</tbody>
								</table>
							</div>
						</Section>
					);
				})}
				<NutritionistSummaryConsent
					sectionFormFields={sectionFormFields}
					validationSchema={validationSchema}
					handleSubmit={handleSubmit}
					pageContext={pageContext}
					loading={loading}
					accessRule={accessRule}
				/>
			</Container>
		</Layout>
	);
};

export default NutritionistSummary;
